
export const dataPublications = [


    {
    "bibtex":`


         @InProceedings{pmlr-v229-abeysirigoonawardena23a,
          title = 	 {Generating Transferable Adversarial Simulation Scenarios for Self-Driving via Neural Rendering},
          author =       {Abeysirigoonawardena, Yasasa and Xie, Kevin and Chen, Chuhan and Khorasgani, Salar Hosseini and Chen, Ruiting and Wang, Ruiqi and Shkurti, Florian},
          booktitle = 	 {7th Conference on Robot Learning (CoRL)},
          pages = 	 {3710--3731},
          year = 	 {2023},
          volume = 	 {229},
          series = 	 {Proceedings of Machine Learning Research},
          month = 	 {06--09 Nov},
          publisher =    {PMLR},

        }

    `,

    "tags":['adversarial scenarios', 'robot vision', 'differentiable rendering', 'simulation'],
    "thumbnail":"/assets/publication-thumbnails/adv_scenarios_nerf_corl23.jpg",
    "video": "https://openreview.net/attachment?id=4uFVn6WHyzo&name=poster_spotlight_video",
    "html": "https://arxiv.org/abs/2309.15770",
    "project": "https://www.yasasa.me/advscenarios/",
    "code": "",
    "description":"",
    },


    
    {
    "bibtex":`

        @article{Huang2024FieldTesting,
           title={Field Testing of a Stochastic Planner for ASV Navigation System using Satellite Images},
           author={Yizhou Huang and Tony Wang and Florian Shkurti and Timothy D. Barfoot},
           booktitle={Field Robotics},
           year={2024},
        }

    `,
    "html":"https://arxiv.org/abs/2309.14657",
    "tags":['planning', 'field robotics', 'robot vision'],
    "code": "",
    "data": "",
    "video": "https://www.youtube.com/watch?v=KVSTmWFLqjk&ab_channel=utiasASRL",
    "thumbnail":"/assets/publication-thumbnails/FieldTestingBoat2023.png",
    "description":"",
    },


    {
    "bibtex":`

       @InProceedings{gu2023conceptgraphs,
         title={ConceptGraphs: Open-Vocabulary 3D Scene Graphs for Perception and Planning},
         author={Qiao Gu and Alihusein Kuwajerwala and Sacha Morin and Krishna Murthy Jatavallabhula and Bipasha Sen and Aditya Agarwal and Corban Rivera and William Paul and Kirsty Ellis and Rama Chellappa and Chuang Gan and Celso Miguel de Melo and Joshua B. Tenenbaum and Antonio Torralba and Florian Shkurti and Liam Paull},
         year={2024},
         booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
       }
    `,

    "tags":['computer vision', 'robot vision', 'manipulation', 'simulation'],
    "thumbnail":"/assets/publication-thumbnails/conceptgraphs2.gif",
    "video": "https://www.youtube.com/watch?v=mRhNkQwRYnc",
    "html": "https://arxiv.org/abs/2309.16650",
    "project": "https://concept-graphs.github.io/",
    "code": "https://github.com/concept-graphs/concept-graphs",
    "description":"",
    },


    {
    "bibtex":`

      @article{darvish2024organa,
      title={ORGANA: A Robotic Assistant for Automated Chemistry Experimentation and Characterization},
      author={Kourosh Darvish and Marta Skreta and Yuchi Zhao and Naruki Yoshikawa and Sagnik Som and Miroslav Bogdanovic and Yang Cao and Han Hao and Haoping Xu and Alán Aspuru-Guzik and Animesh Garg and Florian Shkurti},
      year={2024},
      booktitle={Matter},
      }
    `,

    "tags":['task and motion planning', 'manipulation', 'chemistry robotics'],
    "thumbnail":"/assets/publication-thumbnails/organa_setup.jpg",
    "video": "https://www.youtube.com/watch?v=fpe1_2FeMnE",
    "html": "https://arxiv.org/abs/2401.06949",
    "project": "https://ac-rad.github.io/organa/",
    "code": "",
    "description":"",
    },


    {
    "bibtex":`

       @misc{lee2023stamp,
         title={STAMP: Differentiable Task and Motion Planning via Stein Variational Gradient Descent},
         author={Yewon Lee and Philip Huang and Krishna Murthy Jatavallabhula and Andrew Z. Li and Fabian Damken and Eric Heiden and Kevin Smith and Derek Nowrouzezahrai and Fabio Ramos and Florian Shkurti},
         year={2023},
         eprint={2310.01775},
         archivePrefix={arXiv},
         primaryClass={cs.RO}
       }

    `,

    "tags":['planning', 'task and motion planning', 'manipulation', 'simulation'],
    "thumbnail":"/assets/publication-thumbnails/stamp.jpg",
    "html": "https://arxiv.org/abs/2310.01775",
    "video": "",
    "code": "",
    "description":"",
  },


   {
    "bibtex":`

       @article{samavi2024sicnav,
        title={SICNav: Safe and Interactive Crowd Navigation using Model Predictive Control and Bilevel Optimization},
        author={Sepehr Samavi and James Han and Florian Shkurti and Angela P. Schoellig},
        year={2024},
        booktitle={Transactions on Robotics}
      }

    `,

    "tags":['planning', 'control', 'social navigation', 'human-robot interaction'],
    "thumbnail":"/assets/publication-thumbnails/sicnav.jpg",
    "video": "",
    "project": "",
    "html": "https://arxiv.org/abs/2310.10982",
    "code": "",
    "description":"",
  },


  {
    "bibtex":`


       @article{auro_llms_chemistry_robotics_2023,
          author    = {Naruki Yoshikawa, Marta Skreta, Kourosh Darvish, Sebastian Arellano-Rubach, Zhi Ji, Lasse Bjørn Kristensen, Andrew Zou Li, Yuchi Zhao, Haoping Xu, Artur Kuramshin, Alán Aspuru-Guzik, Florian Shkurti, Animesh Garg},
          title     = {Large Language Models for Chemistry Robotics},
          booktitle = {Autonomous Robots},
          year      = {2023},
          pages     = {1057-1086},
          issue     = {47}
       }

    `,
    "html": "https://link.springer.com/article/10.1007/s10514-023-10136-2",
    "tags":['planning', 'task and motion planning', 'manipulation', 'chemistry robotics'],
    "thumbnail":"/assets/publication-thumbnails/auro23_llms_chemistry_robotics2.gif",
    "video": "https://www.youtube.com/watch?v=-87yrXytluw",
    "code": "https://github.com/ac-rad/xdl-generation",
    "description":"",
    },

    {
    "bibtex":`

       @InProceedings{Wei_2023_CVPR,
          author    = {Cong Wei and Brendan Duke and Ruowei Jiang and Parham Aarabi and Graham Taylor and Florian Shkurti},
          title     = {Sparsifiner: Learning Sparse Instance-Dependent Attention for Efficient Vision Transformers},
          booktitle = {IEEE/CVF Conference on Computer Vision and Pattern Recognition (CVPR)},
          month     = {June},
          year      = {2023},
          pages     = {22680-22689}
       }
    `,
    "html": "https://arxiv.org/abs/2303.13755",
    "tags":['computer vision'],
    "thumbnail":"/assets/publication-thumbnails/cvpr23-sparsifiner.jpg",
    "description":"",
    },

    {
    "bibtex":`

      @InProceedings{Gu_2023_CVPR,
         author    = {Qiao Gu and Dongsub Shim and Florian Shkurti},
         title     = {Preserving Linear Separability in Continual Learning by Backward Feature Projection},
         booktitle = {IEEE/CVF Conference on Computer Vision and Pattern Recognition (CVPR)},
         month     = {June},
         year      = {2023},
         pages     = {24286-24295}
      }

    `,
    "html": "https://arxiv.org/abs/2303.14595",
    "tags":['computer vision', 'robot vision', 'continual learning'],
    "code": "https://github.com/rvl-lab-utoronto/BFP",
    "thumbnail":"/assets/publication-thumbnails/cvpr23-bfp.jpg",
    "description":"",
    },


    {
    "bibtex":`

      @article{jatavallabhula2023conceptfusion,
          title={ConceptFusion: Open-set Multimodal 3D Mapping},
          author={Krishna Murthy Jatavallabhula and Alihusein Kuwajerwala and Qiao Gu and Mohd Omama and Tao Chen and Shuang Li and Ganesh Iyer and Soroush Saryazdi and Nikhil Keetha and Ayush Tewari and Joshua B. Tenenbaum and Celso Miguel de Melo and Madhava Krishna and Liam Paull and Florian Shkurti and Antonio Torralba},
          year={2023},
      booktitle={Robotics: Science and Systems (RSS)},
      }

    `,
    "html": "https://arxiv.org/abs/2302.07241",
    "tags":['computer vision', 'robot vision'],
    "code": "https://concept-fusion.github.io/",
    "video": "https://concept-fusion.github.io/",
    "thumbnail":"/assets/publication-thumbnails/rss23-conceptfusion2.gif",
    "description":"",
    },


    {
    "bibtex":`

       @article{Khodeir2023PolicyGuidedLS,
         title={Policy-Guided Lazy Search with Feedback for Task and Motion Planning},
         author={Mohamed Khodeir and Atharv Sonwane and Ruthrash Hari and Florian Shkurti},
         booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
         year={2023},
       }
    `,
    "html":"https://arxiv.org/abs/2210.14055",
    "tags":['task and motion planning', 'manipulation'],
    "code": "https://github.com/rvl-lab-utoronto/policy-guided-lazy-tamp",
    "data": "https://github.com/rvl-lab-utoronto/policy-guided-lazy-tamp",
    "video": "https://www.youtube.com/watch?v=HjSZOVkXSLU&t=1s&ab_channel=MohamedKhodeir",
    "thumbnail":"/assets/publication-thumbnails/icra23-policy-guided-tamp.jpg",
    "description":"",
    },



    {
    "bibtex":`
      @article{https://doi.org/10.48550/arxiv.2111.13144,
       author = {Mohamed Khodeir and Ben Agro and Florian Shkurti},
       title = {Learning to Search in Task and Motion Planning with Streams},
       journal = {Robotics and Automation Letters (RA-L)},
       year = {2023},
       volume={abs/2111.13144}
    }

    `,
    "html":"https://arxiv.org/abs/2111.13144",
    "tags":['task and motion planning', 'manipulation'],
    "video": "https://rvl.cs.toronto.edu/learning-based-tamp/",
    "code": "https://rvl.cs.toronto.edu/learning-based-tamp/",
    "data": "https://rvl.cs.toronto.edu/learning-based-tamp/",
    "thumbnail":"/assets/publication-thumbnails/ral23-learning-to-search.jpg",
    "description":"",
    },



    {
    "bibtex":`

        @article{Huang2022StochasticPF,
           title={Stochastic Planning for ASV Navigation Using Satellite Images},
           author={Yizhou Huang and Hamza Dugmag and Tim D. Barfoot and Florian Shkurti},
           booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
           year={2023},
        }

    `,
    "html":"https://arxiv.org/abs/2209.11864",
    "tags":['planning', 'field robotics', 'robot vision'],
    "code": "https://pcctp.github.io/",
    "data": "https://pcctp.github.io/",
    "video": "https://pcctp.github.io/",
    "thumbnail":"/assets/publication-thumbnails/icra23-asv-navigation.jpg",
    "description":"",
    },


    {
    "bibtex":`

      @article{wang2023mvtrans,
          title={MVTrans: Multi-View Perception of Transparent Objects},
          author={Yi Ru Wang and Yuchi Zhao and Haoping Xu and Saggi Eppel and Alan Aspuru-Guzik and Florian Shkurti and Animesh Garg},
          year={2023},
          booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
      }
    `,
    "html":"https://arxiv.org/abs/2302.11683",
    "tags":['computer vision', 'robot vision', 'chemistry robotics'],
    "code": "https://ac-rad.github.io/MVTrans/",
    "data": "https://ac-rad.github.io/MVTrans/",
    "video": "https://www.youtube.com/watch?v=8Qdc_xWVp-k&ab_channel=XuHaoping",
    "thumbnail":"/assets/publication-thumbnails/icra23-mvtrans.jpg",
    "description":"",
    },


    {
    "bibtex":`
      @article{https://doi.org/10.48550/arxiv.2212.09672,
           author = {Naruki Yoshikawa and Andrew Zou Li and Kourosh Darvish and Yuchi Zhao and Haoping Xu and Alan Aspuru-Guzik and Animesh Garg and Florian Shkurti},
           title = {Chemistry Lab Automation via Constrained Task and Motion Planning},
           journal = {ArXiv},
           year = {2022},
           volume={abs/2212.09672}
      }
    `,
    "html":"https://arxiv.org/abs/2212.09672",
    "tags":["task and motion planning", 'planning', 'manipulation', 'chemistry robotics'],
    "video": "https://ac-rad.github.io/arc-icra2023/",
    "code": "https://ac-rad.github.io/arc-icra2023/",
    "data": "https://ac-rad.github.io/arc-icra2023/",
    "thumbnail":"/assets/publication-thumbnails/icra23-chemistry-tamp2.gif",
    "description":"",
    },

    {
      "bibtex":`
        @inproceedings{xu2021seeing,
          title={Seeing Glass: Joint Point-Cloud and Depth Completion for Transparent Objects},
          author={Haoping Xu and Yi Ru Wang and Sagi Eppel and Alan Aspuru-Guzik and Florian Shkurti and Animesh Garg},
          booktitle={Conference on Robot Learning (CoRL)},
          year={2021},
          url={https://openreview.net/forum?id=tCfLLiP7vje}
        }
      `,
      "html":"https://openreview.net/forum?id=tCfLLiP7vje",
      "tags":["robot vision"],
      "video": "https://www.youtube.com/watch?v=SuUMKy52b4E&ab_channel=ConferenceonRobotLearning",
      "code": "https://www.pair.toronto.edu/TranspareNet/",
      "data": "https://borealisdata.ca/dataset.xhtml?persistentId=doi:10.5683/SP3/ZJJAJ3",
      "thumbnail":"/assets/publication-thumbnails/todd.jpg",
      "description":"",
    },

    {
    "bibtex":`
      @InProceedings{Xie_2021_ICCV,
        author    = {Xie, Kevin and Wang, Tingwu and Iqbal, Umar and Guo, Yunrong and Fidler, Sanja and Shkurti, Florian},
        title     = {Physics-Based Human Motion Estimation and Synthesis From Videos},
        booktitle = {Proceedings of the IEEE/CVF International Conference on Computer Vision (ICCV)},
        month     = {October},
        year      = {2021},
        pages     = {11532-11541}
    }
    `,
    "html":"https://research.nvidia.com/labs/toronto-ai/physics-pose-estimation-project-page/",
    "tags":['physics based', 'simulation'],
    "video": "https://drive.google.com/file/d/1tdkN64wi1QEEx5EzakYT96uF376S-dWK/view",
    "thumbnail":"/assets/publication-thumbnails/physics_based.png",
    "description":"",
    },

    {
    "bibtex":`
      @inproceedings{agia2021taskography,
         title={Taskography: Evaluating robot task planning over large 3D scene graphs},
         author={Christopher Agia and Krishna Murthy Jatavallabhula and Mohamed Khodeir and Ondrej Miksik and Vibhav Vineet and Mustafa Mukadam and Liam Paull and Florian Shkurti},
         booktitle={Conference on Robot Learning (CoRL)},
         year={2021},
         url={https://openreview.net/forum?id=nWLt35BU1z_}
     }
    `,
    "html":"https://taskography.github.io/",
    "tags":["planning"],
    "video": "https://www.youtube.com/watch?v=mM4v5hP4LdA&t=17s&ab_channel=KrishnaMurthy",
    "code": "https://github.com/taskography",
    "thumbnail":"/assets/publication-thumbnails/taskography.jpg",
    "description":"",
  },

    {
    "bibtex":`
      @InProceedings{Khorasgani_2022_CVPR,
    author    = {Salar Hosseini Khorasgani and Yuxuan Chen and Florian Shkurti},
    title     = {SLIC: Self-Supervised Learning With Iterative Clustering for Human Action Videos},
    booktitle = {IEEE/CVF Conference on Computer Vision and Pattern Recognition (CVPR)},
    month     = {June},
    year      = {2022},
    pages     = {16091-16101}
    }
    `,
    "html":"https://openaccess.thecvf.com/content/CVPR2022/html/Khorasgani_SLIC_Self-Supervised_Learning_With_Iterative_Clustering_for_Human_Action_Videos_CVPR_2022_paper.html",
    "tags":["computer vision"],
    "video": "https://youtu.be/iTt4rOLdjCo",
    "code": "https://github.com/rvl-lab-utoronto/video_similarity_search",
    "thumbnail":"/assets/publication-thumbnails/slic.jpg",
    "description":"",
  },


    {
    "bibtex":`
      @inproceedings{equivariant_imitation_learning,
        title={Augmenting Imitation Experience via Equivariant Representations},
        author={Dhruv Sharma and Alihusein Kuwajerwala and Florian Shkurti},
        year={2022},
        pages={9383-9389},
        booktitle = {International Conference on Robotics and Automation (ICRA)},
      }
    `,
    "html":"https://arxiv.org/abs/2110.07668",
    "tags":["robot vision","imitation learning"],
    "video": "https://youtu.be/l-sCSj7PfmY",
    "thumbnail":"/assets/publication-thumbnails/equivariant_imitation_sharma.jpg",
    "description":"",
  },

    {
    "bibtex":`
      @inproceedings{csc_homanga,
        title={Conservative Safety Critics for Exploration},
        author={Homanga Bharadhwaj and Aviral Kumar and Nicholas Rhinehart and Sergey Levine and Florian Shkurti and Animesh Garg},
        year={2021},
        booktitle = {International Conference on Learning Representations (ICLR)},
      }
    `,
    "html":"https://arxiv.org/abs/2010.14497",
    "tags":["reinforcement learning","safe learning"],
    "thumbnail":"/assets/publication-thumbnails/Conservative Safety Critics for Exploration.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{gradsim,
        title={gradSim: Differentiable simulation for system identification and visuomotor control },
        author={Krishna Jatavallabhula and Miles Macklin and Florian Golemo and Vikram Voleti and Linda Petrini and Martin Weiss and Breandan Considine and Jerome Parent-Levesque and Kevin Xie and Kenny Erleben and Liam Paull and Florian Shkurti and Sanja Fidler and Derek Nowrouzezahrai},
        year={2021},
        booktitle = {International Conference on Learning Representations (ICLR)},
      }
    `,
    "html" : "https://openreview.net/forum?id=c_E8kFWfhp0",
      "tags":["simulation","differentiable rendering","system identification"],
      "project": "https://gradsim.github.io/",
    "thumbnail":"/assets/publication-thumbnails/gradSim Differentiable simulation for system identification and visuomotor control.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{transferable_skills_kevin,
        title={Skill Transfer via Partially Amortized Hierarchical Planning},
        author={Kevin Xie and Homanga Bharadhwaj and Danijar Hafner and Animesh Garg and Florian Shkurti},
        year={2021},
        booktitle = {International Conference on Learning Representations (ICLR)},
      }
    `,
    "html" : "https://openreview.net/forum?id=jXe91kq3jAq",
    "tags":["reinforcement learning"],
    "thumbnail":"/assets/publication-thumbnails/Skill Transfer via Partially Amortized Hierarchical Planning.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{sinha2020dibs,
        title={DIBS: Diversity-Inducing Information Bottleneck in Model Ensembles},
        author={Samarth Sinha and Homanga Bharadhwaj and Anirudh Goyal and Hugo Larochelle and Animesh Garg and Florian Shkurti},
        year={2021},
        booktitle = {Association for the Advancement of Artificial Intelligence (AAAI)},
      }
    `,
    "html" : "https://arxiv.org/abs/2003.04514",
    "tags":["generative models"],
    "thumbnail":"/assets/publication-thumbnails/DIBS Diversity-Inducing Information Bottleneck in Model Ensembles.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{koreitem2020oneshot,
        title={One-Shot Informed Robotic Visual Search in the Wild},
        author={Karim Koreitem and Florian Shkurti and Travis Manderson and Wei-Di Chang and Juan Camilo Gamboa Higuera and Gregory Dudek},
        year={2020},
        eprint={2003.10010},
        archivePrefix={arXiv},
        primaryClass={cs.RO},
        booktitle={IEEE International Conference on Intelligent Robots and Systems (IROS)},
      }
    `,
    "html":"https://arxiv.org/abs/2003.10010",
    "code":"https://github.com/rvl-lab-utoronto/visual_search_in_the_wild",
    "tags":["field robotics","robot vision","human-robot interaction"],
    "thumbnail":"/assets/publication-thumbnails/One-Shot Informed Robotic Visual Search in the Wild.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @INPROCEEDINGS{Manderson-RSS-20,
        AUTHOR    = {Travis Manderson AND Juan Camilo Gamboa Higuera AND Stefan Wapnick AND Jean-François Tremblay AND Florian Shkurti AND David Meger AND Gregory Dudek},
        TITLE     = {{Vision-Based Goal-Conditioned Policies for Underwater Navigation in the Presence of Obstacles}},
        BOOKTITLE = {Robotics: Science and Systems (RSS)},
        YEAR      = {2020},
        ADDRESS   = {Corvalis, Oregon, USA},
        MONTH     = {July},
        DOI       = {10.15607/RSS.2020.XVI.048},
      }
    `,
    "html":"http://www.roboticsproceedings.org/rss16/p048.html",
    "video":"https://www.youtube.com/watch?v=qpcmwb_7QA4",
    "project":"http://www.cim.mcgill.ca/mrl/nav2goal/",
    "tags":["field robotics","robot vision","imitation learning"],
    "thumbnail":"/assets/publication-thumbnails/Vision-Based Goal-Conditioned Policies for Underwater Navigation in the Presence of Obstacles.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{bharadhwaj2020leaf,
        title={LEAF: Latent Exploration Along the Frontier},
        author={Homanga Bharadhwaj and Animesh Garg and Florian Shkurti},
        year={2021},
        eprint={2005.10934},
        archivePrefix={arXiv},
        primaryClass={cs.RO},
        booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
      }
    `,
    "project":"https://sites.google.com/view/leaf-exploration",
    "html":"https://arxiv.org/abs/2005.10934",
    "tags":["reinforcement learning"],
    "thumbnail":"/assets/publication-thumbnails/LEAF Latent Exploration Along the Frontier.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{yuchen_wu_il_rl,
        title={Shaping Rewards for Reinforcement Learning with Imperfect Demonstrations using Generative Models},
        author={Yuchen Wu and Melissa Mozifian and Florian Shkurti},
        year={2021},
        eprint={2011.01298},
        archivePrefix={arXiv},
        primaryClass={cs.RO},
        booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
      }
    `,
    "html":"https://arxiv.org/abs/2011.01298",
    "tags":["reinforcement learning","imitation learning"],
    "thumbnail":"/assets/publication-thumbnails/Shaping Rewards for Reinforcement Learning with Imperfect Demonstrations using Generative Models.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{hypercrl,
        title={Continual Model-Based Reinforcement Learning with Hypernetworks},
        author={Yizhou Huang and Kevin Xie and Homanga Bharadhwaj and Florian Shkurti},
        year={2021},
        eprint={2009.11997},
        archivePrefix={arXiv},
        primaryClass={cs.RO},
        booktitle={IEEE International Conference on Robotics and Automation (ICRA)},
      }
    `,
    "html":"https://arxiv.org/abs/2009.11997",
    "tags":["reinforcement learning","continual learning"],
    "thumbnail":"/assets/publication-thumbnails/Continual Model-Based Reinforcement Learning with Hypernetworks.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{loho,
        title={LOHO: Latent Optimization of Hairstyles via Orthogonalization},
        author={Rohit Saha and Brendan Duke and Florian Shkurti and Graham Taylor and Parham Aarabi},
        year={2021},
        eprint={2103.03891},
        archivePrefix={arXiv},
        primaryClass={cs.CV},
        booktitle={Computer Vision and Pattern Recognition (CVPR)},
      }
    `,
    "html":"https://arxiv.org/abs/2103.03891",
    "tags":["generative models"],
    "thumbnail":"/assets/publication-thumbnails/LOHO Latent Optimization of Hairstyles via Orthogonalization.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{dong2020catch,
        title={Catch the Ball: Accurate High-Speed Motions for Mobile Manipulators via Inverse Dynamics Learning},
        author={Ke Dong and Karime Pereida and Florian Shkurti and Angela P. Schoellig},
        year={2020},
        eprint={2003.07489},
        archivePrefix={arXiv},
        primaryClass={cs.RO},
        booktitle={IEEE International Conference on Intelligent Robots and Systems (IROS)},
      }
    `,
    "html":"https://arxiv.org/abs/2003.07489",
    "video":"https://www.youtube.com/watch?v=4uCvzurthS4",
    "tags":["manipulation","control"],
    "thumbnail":"/assets/publication-thumbnails/Catch the Ball Accurate High-Speed Motions for Mobile Manipulators via Inverse Dynamics Learning.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{bharadhwaj2020modelpredictive,
        title={Model-Predictive Control via Cross-Entropy and Gradient-Based Optimization},
        author={Homanga Bharadhwaj and Kevin Xie and Florian Shkurti},
        year={2020},
        eprint={2004.08763},
        archivePrefix={arXiv},
        primaryClass={cs.LG},
        booktitle={Conference on Learning for Dynamics and Control (L4DC)},
      }
    `,
    "html":"https://arxiv.org/abs/2004.08763",
    "tags":["planning","reinforcement learning"],
    "thumbnail":"/assets/publication-thumbnails/Model-Predictive Control via Cross-Entropy and Gradient-Based Optimization.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @article{Abeysirigoonawardena2019GeneratingAD,
        title={Generating Adversarial Driving Scenarios in High-Fidelity Simulators},
        author={Yasasa Abeysirigoonawardena and Florian Shkurti and Gregory Dudek},
        journal={International Conference on Robotics and Automation (ICRA)},
        year={2019},
        pages={8271-8277},
      }
    `,
    "project":"http://cim.mcgill.ca/~mrl/adversarial_driving_scenarios/",
    "tags":["simulation","adversarial scenarios"],
    "thumbnail":"/assets/publication-thumbnails/Generating Adversarial Driving Scenarios in High-Fidelity Simulators.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{ florianICRA2018,
        author = {Shkurti, Florian and Kakodkar, Nikhil and Dudek, Gregory},
        title = {{Model-Based Probabilistic Pursuit via Inverse Reinforcement Learning}},
        booktitle = "IEEE International Conference on Robotics and Automation (ICRA)",
        year = "2018",
        pages = "7804-7811",
        month = "May",
        address = "Brisbane, Australia",
      }
    `,
    "video":"http://www.cim.mcgill.ca/~florian/pursuit_via_irl.mp4",
    "pdf":"assets/pdf/icra_2018_irl_pursuit.pdf",
    "tags":["planning","imitation learning"],
    "thumbnail":"/assets/publication-thumbnails/Model-Based Probabilistic Pursuit via Inverse Reinforcement Learning.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @INPROCEEDINGS{koreitemoceans18,
        author={Karim Koreitem and Jimmy Li and Ian Karp and Travis Manderson and Florian Shkurti and Gregory Dudek},
        booktitle={IEEE OCEANS},
        title={Synthetically Trained 3D Visual Tracker of Underwater Vehicles},
        year={2018},
        volume={},
        number={},
        pages={1-7},
        month={Oct},
      }
    `,
    "pdf":"assets/pdf/oceans18_synthetic_tracking.pdf",
    "tags":["robot vision","field robotics","simulation"],
    "thumbnail":"/assets/publication-thumbnails/Synthetically Trained 3D Visual Tracker of Underwater Vehicles.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{convoying_iros_2017,
        author = {Shkurti, Florian and Chang, {Wei Di} and Henderson, Peter and Islam, {Md. Jahidul} and {Gamboa Higuera}, {Juan Camilo} and Li, Jimmy and Manderson, Travis and Xu, Anqi and Dudek, Gregory and Sattar, Junaed},
        title = "Underwater Multi-Robot Convoying Using Visual Tracking by Detection",
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "4189--4196",
        year = "2017",
        month = "September",
        address = "Vancouver, Canada",
        video= "http://www.cim.mcgill.ca/~mrl/robot_tracking/",
      }
    `,
    "pdf":"assets/pdf/iros17_visual_convoying.pdf",
    "tags":["robot vision","field robotics"],
    "thumbnail":"/assets/publication-thumbnails/Underwater Multi-Robot Convoying Using Visual Tracking by Detection.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{topological_pursuit_iros_2017,
        author = "Florian Shkurti and Gregory Dudek",
        title = "Topologically distinct trajectory predictions for probabilistic pursuit",
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "5653--5660",
        year = "2017",
        month = "September",
        address = "Vancouver, Canada",
      }
    `,
    "pdf":"assets/pdf/iros17_topological_pursuit.pdf",
    "tags":["planning"],
    "thumbnail":"/assets/publication-thumbnails/Topologically distinct trajectory predictions for probabilistic pursuit.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @article{benchmark_environments_multitask,
        author    = {Peter Henderson and
                    Wei{-}Di Chang and
                    Florian Shkurti and
                    Johanna Hansen and
                    David Meger and
                    Gregory Dudek},
        title     = {Benchmark Environments for Multitask Learning in Continuous Domains},
        journal   = {CoRR},
        volume    = {abs/1708.04352},
        year      = {2017},
        archivePrefix = {arXiv},
        eprint    = {1708.04352},
      }
    `,
    "html":"https://arxiv.org/abs/1708.04352",
    "tags":["reinforcement learning","simulation"],
    "thumbnail":"/assets/publication-thumbnails/Benchmark Environments for Multitask Learning in Continuous Domains.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{manderson_crv_2016,
        Author = {Manderson, Travis and Shkurti, Florian and Dudek, Gregory},
        Booktitle = {Conference on Computer and Robot Vision (CRV)},
        Month = {May},
        Publisher = {IEEE Computer Society},
        Title = {Texture-Aware SLAM Using Stereo Imagery And Inertial Information},
              Pages = {465--463},
              Year = {2016},
      }
    `,
    "pdf":"assets/pdf/crv_2016_texture_aware_slam.pdf",
    "tags":["field robotics","estimation","robot vision"],
    "thumbnail":"/assets/publication-thumbnails/Texture-Aware SLAM Using Stereo Imagery And Inertial Information.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{iros2014_megerShkurtiCortesPozaGiguereDudek,
        author = {David Meger and Florian Shkurti and David Cort'{e}s Poza and Philippe Gigu\`{e}re and Gregory Dudek},
        title = {3D Trajectory Synthesis and Control for a Legged Swimming Robot},
        booktitle = {Proceedings of the IEEE International Conference on Robotics and Intelligent Systems (IROS)},
        year = {2014},
      }
    `,
    "pdf":"assets/pdf/iros2014_3d_autopilot.pdf",
    "project":"http://www.cim.mcgill.ca/~dmeger/IROS2014_3DTrajectories/",
    "tags":["field robotics","control"],
    "thumbnail":"/assets/publication-thumbnails/3D Trajectory Synthesis and Control for a Legged Swimming Robot.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{Qiwen14iros,
        author = {Zhang, Qiwen and Whitney, David and Shkurti, Florian and Rekleitis, Ioannis},
        title = {{Ear-based Exploration on Hybrid Metric/Topological Maps}},
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "3081--3088",
        year = "2014",
        month = "September",
        address = "Chicago, USA",

      }
    `,
    "pdf":"assets/pdf/iros2014_gvg.pdf",
    "tags":["planning"],
    "thumbnail":"/assets/publication-thumbnails/Ear-based Exploration on Hybrid MetricTopological Maps.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{Meghjani:2014:ARS:2623380.2623579,
        author = {Meghjani, Malika and Shkurti, Florian and Higuera, Juan Camilo Gamboa and Kalmbach, Arnold and Whitney, David and Dudek, Gregory},
        title = {Asymmetric Rendezvous Search at Sea},
        booktitle = {Conference on Computer and Robot Vision (CRV)},
        year = {2014},
        pages = {175--180},
      }
    `,
    "pdf":"assets/pdf/crv2014_asymmetric_rendezvous.pdf",
    "tags":["field robotics","planning","control"],
    "thumbnail":"/assets/publication-thumbnails/Asymmetric Rendezvous Search at Sea.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{ Florian2014MaxViz,
        author = {Shkurti, Florian and Dudek, Gregory},
        title = {{Maximizing Visibility in Collaborative Trajectory Planning}},
        booktitle = "IEEE International Conference on Robotics and Automation (ICRA)",
        pages = "3771-3776",
        year = "2014",
        month = "May",
        address = "Hong Kong",
      }
    `,
    "pdf":"assets/pdf/icra2014_max_viz_planning.pdf",
    "tags":["planning"],
    "thumbnail":"/assets/publication-thumbnails/Maximizing Visibility in Collaborative Trajectory Planning.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{Shkurti13icra,
        author = {Shkurti, Florian and Dudek, Gregory},
        title = {{On the Complexity of Searching for an Evader with a Faster Pursuer}},
        booktitle = "IEEE International Conference on Robotics and Automation (ICRA)",
        pages = "4047--4052",
        year = "2013",
        month = "May",
        address = "Karlsruhe, Germany",
      }
    `,
    "pdf":"assets/pdf/icra2013_complexity_pursuit_evasion.pdf",
    "tags":["planning"],
    "thumbnail":"/assets/publication-thumbnails/On the Complexity of Searching for an Evader with a Faster Pursuer.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{Shkurti12iros,
        author = {Shkurti, Florian and Xu, Anqi and Meghjani, Malika and {Gamboa Higuera}, {Juan Camilo} and  Girdhar, Yogesh and Giguere, Philippe and Dey, {Bir Bikram} and Li, Jimmy and Kalmbach, Arnold and Prahacs, Chris and Turgeon, Katrine and Rekleitis, Ioannis and Dudek, Gregory},
        title = {{Multi-Domain Monitoring of Marine Environments Using a Heterogeneous Robot Team}},
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "1747--1753",
        year = "2012",
        month = "October",
        address = "Algarve, Portugal",
      }
    `,
    "pdf":"assets/pdf/iros2012_multirobot_env_monitoring.pdf",
    "project":"http://www.cim.mcgill.ca/~mrl/multi_robot_env_monitoring/",
    "video":"https://www.youtube.com/watch?time_continue=1&v=DvWVC5R0zqs",
    "tags":["field robotics"],
    "thumbnail":"/assets/publication-thumbnails/Multi-Domain Monitoring of Marine Environments Using a Heterogeneous Robot Team.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{ Gamboa2012SocialPlan,
        author = {Juan Camilo Gamboa Higuera and Anqi Xu and Florian Shkurti and Gregory Dudek},
        title = {Socially-Driven Collective Path Planning for Robot Missions},
        booktitle = {Conference on Computer and Robot Vision (CRV)},
        year = {2012},
        pages = {417--424},
        address = {Toronto, Canada},
        month = {May},
      }
    `,
    "pdf":"assets/pdf/crv2012_social_plan.pdf",
    "tags":["planning"],
    "thumbnail":"/assets/publication-thumbnails/Socially-Driven Collective Path Planning for Robot Missions.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{florian_state_est,
        author = {Shkurti, Florian and Rekleitis, Ioannis and Scaccia, Milena and Dudek, Gregory},
        title = "{State estimation of an underwater robot using visual and inertial information}",
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "5054--5060",
        year = "2011",
        month = "September",
        address = "San Francisco, USA",
      }
    `,
    "pdf":"assets/pdf/iros_2011_state_est.pdf",
    "tags":["estimation", "field robotics"],
    "thumbnail":"/assets/publication-thumbnails/State estimation of an underwater robot using visual and inertial information.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @InProceedings{ Girdhar2011MARE,
        author = "Yogesh Girdhar and Anqi Xu and Bir Bikram Dey and Malika Meghjani and Florian Shkurti and Ioannis Rekleitis and Gregory Dudek",
        title = "{MARE: Marine Autonomous Robotic Explorer}",
        booktitle = "IEEE International Conference on Intelligent Robots and Systems (IROS)",
        pages = "5048--5053",
        year = "2011",
        month = "September",
        address = "San Francisco, USA",
      }
    `,
    "pdf":"assets/pdf/iros2011_boat.pdf",
      "tags":["field robotics", "robot vision"],
    "thumbnail":"/assets/publication-thumbnails/MARE Marine Autonomous Robotic Explorer.jpg",
    "description":"",
  },
  {
    "bibtex":`
      @inproceedings{florian_crv_2011,
        Author = {Shkurti, Florian and Rekleitis, Ioannis and Dudek, Gregory},
        Booktitle = {Conference on Computer and Robot Vision},
        Month = {May},
        Publisher = {IEEE Computer Society},
        Title = {Feature Tracking Evaluation for Pose Estimation in Underwater Environments},
              Pages = {160--167},
              Year = {2011},
      }
    `,
    "pdf":"assets/pdf/crv2011_underwater_ft.pdf",
    "tags":["field robotics","robot vision"],
    "thumbnail":"/assets/publication-thumbnails/Feature Tracking Evaluation for Pose Estimation in Underwater Environments.jpg",
    "description":"",
  },
]
